<template lang="pug">
div
  .content-loader-center.m-0.h-100(v-if='isLoadingActivity || isCreatingActivity || isUpdatingActivity')
    .text-center.flex-center
      .loading-bg-inner 
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | {{ isLoadingActivity ? 'Chargement du formulaire d\'édition d\'un rapport d\'activité commerciale' : 'Enregistrement du rapport d\'activité commerciale...' }}
  .h-100(v-else)
    b-row.m-0.justify-content-center
      b-col.content-header(cols='9')
        .d-flex.align-items-start.w-100.justify-content-between
          .d-flex
            h2.content-header-title.float-left.m-0.pr-1.mr-1(style="border-right: 1px solid #e2e2e2")
              | {{ $route.meta.pageTitle }}
            .content-header-search.mr-1
              h3.m-0(style="text-transform: capitalize;")
                | {{  getTitle }}
          .content-header-actions.d-flex
            feather-icon.cursor(icon='XIcon' size='24' @click='cancel()')
    b-row.m-0.justify-content-center(style="height: calc(100% - 110px)")
      b-col.h-100(cols='9')
        form.h-100(:id="'formEditActivity'+key" style="overflow-x: hidden")
            b-row.my-0
                b-col.py-0.mb-0
                    h4.cursor-pointer Prospect/Client cible
            b-row.my-0
                b-col.py-0.mb-1
                    b-form-group.mb-1(label='' label-for='company')
                        ejs-dropdownlist(id='company' name="company" placeholder='Selectionnez un prospect' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true'  :dataSource="companiesList" :fields="{ text: 'name', value: 'id' }" v-model='dataForm.companyId' required=""  @change="setCompany")
                            template(v-slot:footerTemplate)
                                div.p-50
                                    ejs-button(v-on:click.native="newCompany()" :content="'+ Nouveau prospect'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
            b-row.my-0
                b-col.py-0
                    .card.bg-light(v-if="dataCompany && dataCompany.id" style="box-shadow: none;")
                        b-row.m-0
                            b-col(md="12" lg="6" xl="12")
                                h4.d-flex.align-items-center.justify-content-between.text-primary.bold 
                                    | {{ dataCompany.name }}
                                    span.material-icons-outlined.ml-1.cursor-pointer.text-primary(style="font-size: 14px;" @click="editCompany(dataCompany)") open_in_new
                                .w-100.d-flex 
                                    div(v-if="dataCompany.address != null")
                                        span {{ dataCompany.address }}
                                    div(v-if="dataCompany.addressComplement != null")
                                        span {{ dataCompany.addressComplement }}
                                    div(v-if="dataCompany.zipCode != null || dataCompany.city != null")
                                        span {{ dataCompany.zipCode }} {{ dataCompany.city }}
                                    div(v-if="dataCompany.country != null")
                                        span {{ dataCompany.country }}
                                    div(v-if="dataCompany.country != null")
                                        span {{ dataCompany.country }}
                                .w-100.d-flex
                                    .d-flex.align-items-center(v-if="dataCompany.phoneNumber")
                                        span.material-icons-outlined(style="font-size: 14px; margin-right: 5px") phone
                                        a(:href="'tel:'+dataCompany.phoneNumber") {{ dataCompany.phoneNumber }}
                                    .d-flex.align-items-center(v-if="dataCompany.secondaryPhoneNumber")
                                        span.material-icons-outlined(style="font-size: 14px; margin: 0 5px") phone
                                        a(:href="'tel:'+dataCompany.secondaryPhoneNumber") {{ dataCompany.secondaryPhoneNumber }}
                                    .d-flex.align-items-center(v-if="dataCompany.email")
                                        span.material-icons-outlined(style="font-size: 14px; margin: 0 5px") mail
                                        a(:href="'mailto:'+dataCompany.email") {{ dataCompany.email }}
                            b-col.py-0.d-flex.justify-content-between.w-100.align-items-center(md="12")
                                h5(style="margin-top: 3px")
                                    | Contacts liés
                                .line
                                ejs-button(v-on:click.native.prevent="clickAction" content="+ Nouveau contact" :isPrimary="true" locale='fr' :cssClass="'e-outline e-small'")
                            b-col(md="12")
                                p.w-100.text-center.py-50.m-0(v-if="dataCompany.contacts.length==0 && dataCompanyLoading") Ce prospect/client ne dispose d'aucun contact
                                sync-grid#overviewgrid.details(v-else ref="overviewgrid" :gridData="dataCompany.contacts" :name="name" :allowPaging="allowPaging" :headerData="headerData" :allowGrouping="allowGrouping" :tagguerButton="tagguerButton" :editSettings="editSettings" @actionBegin="actionBegin" @deleteButtonClicked="deleteButtonClicked")
                            b-col.py-0.d-flex.justify-content-between.w-100.align-items-center.mt-2(md="12")
                                h5(style="margin-top: 3px")
                                    | Historique des activités commerciales
                                .line.mr-0
                            b-col(md="12")
                                p.w-100.text-center.py-50.m-0(v-if="dataFormActivities.length==0 && dataCompanyLoading") Aucune activité commerciale n'a été enregistrée pour ce prospect/client
                                sync-grid#overviewsubgrid.details(v-else style="max-height: 30vh !important" ref="overviewsubgrid" :key="'overviewsubgrid_'+dataCompany.id" :gridData="dataFormActivities" :name="nameHistoric" :allowPaging="allowPaging" :headerData="headerDataHistoric" :allowGrouping="allowGrouping" @actionBegin="actionBeginHistoric")
                            
            vs-divider.mb-50.mt-2(color='#cccccc')
            b-row.my-0
                b-col(sm="12" md="6" style='padding-bottom:0px !important')
                    h4.cursor-pointer Détails de l'action
                    b-row.my-0
                        b-col(style="padding-top: 0px !important; padding-bottom: 0px !important;")
                            b-form-group.mb-1(label='Date de contact' label-for='activityDate')
                                ejs-datetimepicker(id="activityDate" name="activityDate" locale='fr' :firstDayOfWeek="firstDayOfWeek" v-model="dataForm.activityDate" placeholder="JJ/MM/AAAA HH:MM")
                            b-form-group.mb-1(label='Interlocuteur(trice)' label-for='contact')
                                ejs-dropdownlist(id='contact' :enabled="isCompanySelected()" name="contact" placeholder='Selectionnez un interlocuteur' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="contactsList" :fields="{ text: 'displayLabel', value: 'id' }" v-model='dataForm.contactId')
                                    template(v-slot:footerTemplate)
                                        div.p-50
                                            ejs-button(v-on:click.native.prevent="clickAction" :content="'+ Nouveau contact'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
                                span.text-danger(v-if="!dataForm.companyId" style="font-size: 0.8rem") Aucun prospect/client cible sélectionné.
                        b-col.py-0(v-if="!isSingle")
                            b-form-group.mb-1(label='Effectué par' label-for='collaborator')
                                ejs-dropdownlist(id='collaborator' name="collaborator" placeholder='Selectionnez un collaborateur' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="collaboratorsList" :fields="{ text: 'displayLabel', value: 'id' }" v-model='dataForm.collaboratorId')
                                    template(v-slot:footerTemplate)
                                        div.p-50
                                            ejs-button(v-on:click.native="newCollaborator()" :content="'+ Nouveau collaborateur'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
                    b-form-group.mb-1(label='Origine de contact' label-for='activitySourceType')    
                        ejs-radiobutton(cssClass="mr-1" :label='activitySourceType.label' :checked="dataForm.activitySourceTypeId == activitySourceType.id ? true : false" v-model="dataForm.activitySourceTypeId" :htmlAttributes="{ value: parseInt(activitySourceType.id) }" name='activitySourceType' :key="index" v-for="(activitySourceType, index) of activitySourceTypesList")

                    b-form-group(label='Canal de contact' label-for='activityChanelType')    
                        ejs-radiobutton(cssClass="mr-1" :label='activityChanelType.label' :checked="dataForm.activityChanelTypeId == activityChanelType.id ? true : false" v-model="dataForm.activityChanelTypeId" :htmlAttributes="{ value: parseInt(activityChanelType.id) }" name='activityChanelType' :key="index" v-for="(activityChanelType, index) of activityChanelTypesList")
    
                b-col(sm="12" md="6" style='padding-bottom:0px !important')
                    h4.cursor-pointer Résultat de l'action
                    b-form-group.mb-1(label='Type de résultat' label-for='typeResult')    
                        ejs-radiobutton(cssClass="mr-1" :label='activityResultType.label' :checked="dataForm.activityResultTypeId == activityResultType.id ? true : false"  v-model="dataForm.activityResultTypeId" :htmlAttributes="{ value: parseInt(activityResultType.id) }" name='activityResultType' :key="index" v-for="(activityResultType, index) of activityResultTypesList")
                    b-form-group.mb-1(label='Commentaire' label-for='comments')
                        ejs-textbox(id="comments" :multiline="true" v-model='dataForm.comments' placeholder="Commentaire court")
                    .d-flex 
                        b-form-group.mb-1.mr-1.text-center.d-flex.flex-column(label-for="isUnreachable"  label="NRP")
                            ejs-checkbox(cssClass="mt-50" id="isUnreachable" v-model="dataForm.isUnreachable")
                        b-form-group.mb-1.mr-1.text-center.d-flex.flex-column(label-for="hasLeavingMessage"  label="Message laissé")
                            ejs-checkbox(cssClass="mt-50" id="hasLeavingMessage" v-model="dataForm.hasLeavingMessage")
                        b-form-group.mb-1(label='Date de relance' label-for='reminderDate' style='flex:1')   
                            ejs-datetimepicker(id="reminderDate" :serverTimezoneOffset="1" locale='fr' :firstDayOfWeek="firstDayOfWeek" v-model="dataForm.reminderDate" placeholder="JJ/MM/AAAA HH:MM")
                    .d-flex 
                        b-form-group.mb-1.mr-1.text-center.d-flex.flex-column(label-for="isTransfer" label="Transférer le contact")
                            ejs-checkbox(cssClass="mt-50" id="isTransfer" v-model="dataForm.isTransfer")
                        b-form-group.mb-1(v-if="dataForm.isTransfer" label-for="transferCollaborator"  label="Collaborateur" style='flex:1')
                            ejs-dropdownlist(id='transferCollaborator' name="transferCollaborator" placeholder='Selectionnez un collaborateur' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="collaboratorsList.map(elem => { label = elem.firstName + ' ' + elem.lastName; return { ...elem, label: label }})" :fields="{ text: 'label', value: 'id' }" v-model='dataForm.transferCollaboratorId' required="")
                                template(v-slot:footerTemplate)
                                    div.p-50
                                        ejs-button(v-on:click.native="newCollaborator()" :content="'+ Nouveau collaborateur'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
        .flex.flex-column
          vs-divider.mb-50(color='#cccccc')
          b-row.my-0
              b-col.py-0(cols='12')
                  .d-flex.justify-content-between
                      div
                          ejs-button(v-on:click.native="cancel()" content="Annuler" :isPrimary="true" locale='fr' :cssClass="'e-outline mr-1'")
                          ejs-button(v-if="dataForm.id" v-on:click.native="archiveActivityLocal(dataForm.id)" content='' cssClass='e-outline e-danger m-0' iconCss='e-btn-sb-icons e-icon-archive'  :isPrimary="true" locale='fr')
                      .e-btn-group.e-lib.e-css
                          ejs-button(v-on:click.native="confirm(1)" :disabled="!dataForm.companyId || !dataForm.collaboratorId" :content="dataForm.id ? 'Enregistrer et saisir un autre' : 'Ajouter et saisir un autre'" :isPrimary="true" locale='fr' :iconCss="'e-btn-sb-icons e-icons e-save'" iconPosition="Right")
                          ejs-button(v-on:click.native="confirm(0)" :disabled="!dataForm.companyId || !dataForm.collaboratorId" :content="dataForm.id ? 'Enregistrer et Fermer' : 'Ajouter et fermer'" :isPrimary="true" locale='fr' :iconCss="'e-btn-sb-icons e-icons e-save'" iconPosition="Right" style="border-left: 1px solid #ffffff !important;")
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { ECivility } from "@/types/api-orisis/enums/enums";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { civilityTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { FormValidator } from "@syncfusion/ej2-vue-inputs";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import DateRanger from "@/components/global/date-ranger/DateRanger.vue";

export default {
  components: {
    DateRanger,
    SyncGrid: () => import("@/components/global/grid/Grid.vue"),
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "single",
    },
    companyId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // Gestion du form principal
      firstDayOfWeek: 1,
      name: "editActivity",
      nameHistoric: "editActivityHistoric",
      dateRangeDefault: null, //[new Date(dayjs().format("YYYY-MM-DD")).toISOString(), new Date(dayjs().format("YYYY-MM-DD")).toISOString()],
      dataFormActivities: [],
      dataForm: {
        id: 0,
        companyId: null,
        collaboratorId: null,
        comments: null,
        activitySourceTypeId: 0,
        activityChanelTypeId: 0,
        activityResultTypeId: 0,
        reminderDate: null,
        activityDate: new Date(
          dayjs().format("YYYY-MM-DD HH:mm:00")
        ).toISOString(),
        isUnreachable: false,
        hasLeavingMessage: false,
        isTransfer: false,
        transferCollaboratorId: 0,
      },
      dataOrigine: {},
      loading: true,
      dataCompany: {},
      dataCompanyLoading: true,
      key: 1,
      headerDataHistoric: [],
      headerData: [],
      tagguerButton: false,
      allowGrouping: false,
      allowPaging: false,
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouveau contact",
        titleEdit: "Modifier contact",
        titleField: "label",
      },

      formObj: "",
      options: {
        rules: {
          company: {
            required: [true, "Sélectionnez une société cible"],
          },
          collaborator: {
            required: [true, "Sélectionnez un collaborateur"],
          },
          activityDate: {
            required: [true, "Sélectionnez une date"],
          },
        },
      },

      optionCivility: [
        {
          label: "M. et Mme",
          value: ECivility.MonsieurMadame,
        },
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },

        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "userCollaboratorId",
      "collaboratorsList",
      "companiesList",
      "company",
      "activityChanelTypesList",
      "activityResultTypesList",
      "activitySourceTypesList",
      "isLoadingActivity",
      "isCreatingActivity",
      "isUpdatingActivity",
      "institutionSelected",
      "contactsList",
    ]),
    contactsList() {
      if (this.dataCompany && this.dataCompany.contacts) {
        return this.dataCompany.contacts.map((el) => {
          return { ...el, displayLabel: el.firstName + " " + el.lastName };
        });
      } else {
        return [];
      }
    },
    collaboratorsList() {
      return this.$store.getters["collaboratorsList"].map((el) => {
        return { ...el, displayLabel: el.firstName + " " + el.lastName };
      });
    },
    getTitle() {
      return this.dataForm.activityDate
        ? dayjs(this.dataForm.activityDate).format("dddd D MMMM YYYY")
        : "";
      // return (this.dataCompany && this.dataCompany.id ? this.dataCompany.isParticular ?  civilityTranslate(this.dataCompany.civility) + this.dataCompany.firstName + ' ' + this.dataCompany.lastName + ' ' : this.dataCompany.name + ' ' : '')  + (this.dataForm.activityDate ? dayjs(this.dataForm.activityDate).format("dddd D MMMM YYYY") : "")
    },
    isSingle() {
      return this.$route.meta.mode == "single";
    },
  },
  async created() {
    this.setHeaderData();
    await this.getCollaborators({});
    await this.getCompanies({});
    await this.getActivityChanelTypes({});
    await this.getActivityResultTypes({});
    await this.getActivitySourceTypes({});

    if (this.id > 0) {
      this.getActivityById({ activityId: this.id }).then((res) => {
        this.dataForm = res;
        this.dataOrigine = JSON.stringify(this.dataForm);
        if (this.$route.meta.mode == "single") {
          this.options = {
            rules: {
              company: {
                required: [true, "Sélectionnez une société cible"],
              },
              activityDate: {
                required: [true, "Sélectionnez une date"],
              },
            },
          };
        } else {
          this.options = {
            rules: {
              company: {
                required: [true, "Sélectionnez une société cible"],
              },
              collaborator: {
                required: [true, "Sélectionnez un collaborateur"],
              },
              activityDate: {
                required: [true, "Sélectionnez une date"],
              },
            },
          };
        }
        this.key++;
        this.formObj = null;
        this.loading = false;
        this.$nextTick(() => {
          this.formObj = new FormValidator(
            "#formEditActivity" + this.key,
            this.options
          );
        });
      });
    } else {
      this.initializeForm();
    }
  },
  mounted() {
    if (this.$route.meta.mode == "single" && this.userCollaboratorId) {
      this.dataForm.collaboratorId = parseInt(this.userCollaboratorId);
    }
  },
  methods: {
    ...mapActions([
      "getCollaborators",
      "getCompanies",
      "getCompanyById",
      "getActivityChanelTypes",
      "getActivityResultTypes",
      "getActivitySourceTypes",
      "createActivity",
      "updateActivity",
      "getActivityById",
      "archiveActivities",
      "updateContact",
      "createContact",
      "updateCompany",
      "getActivitiesByCompanyId",
      "deleteActivities",
    ]),
    formatCurrency,
    civilityTranslate,
    formatDateLocale(str) {
      return (
        dayjs(str).format("dddd D MMMM YYYY").charAt(0).toUpperCase() +
        dayjs(str).format("dddd D MMMM YYYY").slice(1)
      );
    },
    isCompanySelected() {
      return this.dataForm.companyId ? true : false;
    },
    newCompany() {
      if (this.$route.meta.mode == "single") {
        this.$router.push({
          path: "/directory/new-company/clients/5",
          params: { routeOrigine: "directoty/new-activity/" },
        });
      } else {
        this.$router.push({
          path: "/directory/new-company/clients/5",
          params: { routeOrigine: "directoty/new-activity-team/" },
        });
      }
    },
    newCollaborator() {
      if (this.$route.meta.mode == "single") {
        this.$router.push({
          name: "new-collaborator",
          params: { routeOrigine: "directoty/new-activity/" },
        });
      } else {
        this.$router.push({
          name: "new-collaborator",
          params: { routeOrigine: "directoty/new-activity-team/" },
        });
      }
    },
    editContact(contact) {
      if (this.$route.meta.mode == "single") {
        this.$router.push({
          name: "edit-contact",
          params: {
            id: contact.id,
            title: "Edit : " + contact.firstName + " " + contact.lastName,
            tips:
              "Editer le contact : " +
              contact.firstName +
              " " +
              contact.lastName,
            routeOrigine: "directoty/new-activity/",
          },
        });
      } else {
        this.$router.push({
          name: "edit-contact",
          params: {
            id: contact.id,
            title: "Edit : " + contact.firstName + " " + contact.lastName,
            tips:
              "Editer le contact : " +
              contact.firstName +
              " " +
              contact.lastName,
            routeOrigine: "directoty/new-activity-team/",
          },
        });
      }
    },
    editCompany(company) {
      if (this.$route.meta.mode == "single") {
        this.$router.push({
          name: "company",
          params: {
            id: company.id,
            title:
              "Société : " +
              (company.name
                ? company.name
                : company.firstName + " " + company.lastName),
            tips:
              "Société : " +
              (company.name
                ? company.name
                : company.firstName + " " + company.lastName),
            routeOrigine: "directoty/new-activity/",
          },
        });
      } else {
        this.$router.push({
          name: "company",
          params: {
            id: company.id,
            title:
              "Société : " +
              (company.name
                ? company.name
                : company.firstName + " " + company.lastName),
            tips:
              "Société : " +
              (company.name
                ? company.name
                : company.firstName + " " + company.lastName),
            routeOrigine: "directoty/new-activity-team/",
          },
        });
      }
    },
    setHeaderData() {
      this.headerDataHistoric = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: false,
        },
        {
          type: "date",
          field: "activityDate",
          headerText: "Date",
          width: 140,
          maxWidth: 140,
          format: "dd/MM/yyyy HH:mm",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
          defaultValue: new Date(),
          editType: "datetimepickeredit",
          direction: "descending",
        },
        {
          type: "string",
          field: "collaboratorId",
          headerText: "Réalisé par",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          filterTemplate: "collaboratorFilterTemplate",
          visible: true,
          template: "collaboratorTemplate",
        },
        {
          type: "string",
          field: "contactId",
          headerText: "Interlocuteur(trice)",
          template: "contactTemplate",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
          filterTemplate: "contactFilterTemplate",
        },
        {
          type: "string",
          field: "activitySourceType.label",
          headerText: "Source",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "activityChanelType.label",
          headerText: "Canal",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "activityResultType.label",
          headerText: "Résultat",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "comments",
          headerText: "Commentaire",
          minWidth: 300,
          allowFiltering: true,
          allowSorting: true,
          allowGrouping: false,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
          filter: { type: "Menu", operator: "contains" },
        },
        {
          type: "boolean",
          field: "isUnreachable",
          headerText: "NRP",
          width: 90,
          maxWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "boolean",
          editType: "booleanedit",
        },
        {
          type: "boolean",
          field: "hasLeavingMessage",
          headerText: "Message laissé",
          width: 90,
          maxWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "boolean",
          editType: "booleanedit",
        },
        {
          type: "date",
          format: "formatDateOptions",
          field: "reminderDate",
          headerText: "Date relance",
          width: 140,
          maxWidth: 140,
          template: "dateYmdTemplate",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
          defaultValue: new Date(),
          editType: "datetimepickeredit",
        },
      ];
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: false,
        },
        {
          type: "string",
          field: "label",
          headerText: "Libellé",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "civility",
          headerText: "Civilité",
          template: "civilityTemplate",
          width: 100,
          maxWidth: 120,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          foreignKeyValue: "label",
          dataSource: this.optionCivility,
          defaultValue: 1,
          edit: {
            source: this.optionCivility,
            fields: { text: "label", value: "value" },
            type: "dropdown",
            name: "contactCivility",
            origine: "companyContacts",
          },
        },
        {
          type: "string",
          field: "lastName",
          headerText: "Nom",
          minWidth: 160,
          validationRules: { required: true },
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          edit: {
            source: this.contactsList,
            fields: { text: "lastName", value: "lastName" },
            type: "autocomplete",
            name: "contactLastName",
            origine: "companyContacts",
          },
          visible: true,
        },
        {
          type: "string",
          field: "firstName",
          headerText: "Prénom",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          edit: {
            source: this.contactsList,
            fields: { text: "firstName", value: "firstName" },
            type: "autocomplete",
            name: "contactFirstName",
            origine: "companyContacts",
          },
          visible: true,
        },
        {
          type: "string",
          field: "phoneNumber",
          headerText: "Téléphone",
          width: 120,
          minWidth: 120,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          edit: {
            source: this.contactsList,
            fields: { text: "phoneNumber", value: "phoneNumber" },
            type: "autocomplete",
            name: "contactPhoneNumber",
            origine: "companyContacts",
          },
          visible: true,
        },
        {
          type: "string",
          field: "secondaryPhoneNumber",
          headerText: "Tél secondaire",
          width: 120,
          minWidth: 120,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          edit: {
            type: "textbox",
            name: "contactSecondaryPhoneNumber",
            origine: "companyContacts",
          },
          visible: true,
        },
        {
          type: "string",
          field: "email",
          headerText: "E-mail",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          edit: {
            type: "textbox",
            name: "contactEmail",
            origine: "companyContacts",
          },
          visible: true,
        },
        {
          type: "number",
          field: "id",
          headerText: "ID",
          foreignKeyField: "id",
          width: 90,
          maxWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          defaultValue: 0,
          visible: false,
          edit: {
            type: "textbox",
            name: "contactId",
            origine: "companyContacts",
          },
        },
      ];
    },

    async actionBeginHistoric(args) {
      if (args.requestType === "save" && args.action === "add") {
        this.dataFormActivities.push({
          ...args.data,
          institutionId: this.institutionSelected.id,
        });
        await this.createActivity({
          activity: args.data,
          route: "activity",
        }).then((res) => {
          this.dataFormActivities.splice(
            this.dataFormActivities.length - 1,
            1,
            res[0]
          );
          this.$refs.overviewsubgrid.$refs.overviewgrid.refresh();
        });
      }
      if (args.requestType === "save" && args.action === "edit") {
        this.dataFormActivities.splice(args.rowIndex, 1, args.data);
        await this.updateActivity({ activity: args.data, route: "activity" });
      }
      if (args.requestType === "delete") {
        for (let index = 0; index < args.data.length; index++) {
          const element = args.data[index];
          await this.deleteActivities({
            activityIds: [element.id],
            route: "activity",
          });
        }
      }
    },

    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        let labelContact = args.data.label;
        this.dataCompany.contacts.push(args.data);
        if (args.data.id > 0) {
          await this.updateContact({ contact: args.data }).then((res) => {
            this.dataCompany.contacts.splice(
              this.dataCompany.contacts.length - 1,
              1,
              { ...res, label: labelContact }
            );
            this.$refs.overviewgrid.$refs.overviewgrid.refresh();
          });
        } else {
          await this.createContact({ contact: args.data }).then((res) => {
            this.dataCompany.contacts.splice(
              this.dataCompany.contacts.length - 1,
              1,
              { ...res, label: labelContact }
            );
            this.$refs.overviewgrid.$refs.overviewgrid.refresh();
          });
        }
        this.updateCompany({
          company: {
            ...this.dataCompany,
            contacts: this.dataCompany.contacts
              ? this.dataCompany.contacts.map((el) => {
                  return { contactId: el.id, label: el.label };
                })
              : [],
          },
        }).then((res) => {
          this.dataCompany.contacts = this.dataCompany.contacts
            ? this.dataCompany.contacts.map((el) => {
                return { ...el, label: el.label };
              })
            : [];
        });
      }
      if (args.requestType === "save" && args.action === "edit") {
        this.dataCompany.contacts.splice(args.rowIndex, 1, args.data);
        await this.updateContact({ contact: args.data });

        this.updateCompany({
          company: {
            ...this.dataCompany,
            contacts: this.dataCompany.contacts
              ? this.dataCompany.contacts.map((el) => {
                  return { contactId: el.id, label: el.label };
                })
              : [],
          },
        }).then((res) => {
          this.dataCompany.contacts = this.dataCompany.contacts
            ? this.dataCompany.contacts.map((el) => {
                return { ...el, label: el.label };
              })
            : [];
        });
      }
    },
    async deleteButtonClicked(args) {
      this.dataCompany.contacts.splice(
        this.dataCompany.contacts.findIndex(
          (elem) => elem.id == args.rowData.id
        ),
        1
      );
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      this.updateCompany({
        company: {
          ...this.dataCompany,
          contacts: this.dataCompany.contacts
            ? this.dataCompany.contacts.map((el) => {
                return { contactId: el.id, label: el.label };
              })
            : [],
        },
      }).then((res) => {
        this.dataCompany.contacts = this.dataCompany.contacts
          ? this.dataCompany.contacts.map((el) => {
              return { ...el, label: el.label };
            })
          : [];
      });
    },
    initializeForm() {
      this.loading = true;
      this.dataForm = {
        id: 0,
        collaboratorId:
          this.$route.meta.mode == "single" && this.userCollaboratorId
            ? parseInt(this.userCollaboratorId)
            : null,
        companyId: this.companyId ? this.companyId : null,
        comments: null,
        activitySourceTypeId:
          this.activitySourceTypesList &&
          this.activitySourceTypesList.length > 0
            ? this.activitySourceTypesList[0].id
            : 0,
        activityChanelTypeId:
          this.activityChanelTypesList &&
          this.activityChanelTypesList.length > 0
            ? this.activityChanelTypesList[0].id
            : 0,
        activityResultTypeId:
          this.activityResultTypesList &&
          this.activityResultTypesList.length > 0
            ? this.activityResultTypesList[0].id
            : 0,
        reminderDate: null,
        activityDate: new Date(
          dayjs().format("YYYY-MM-DD HH:mm:00")
        ).toISOString(),
        isUnreachable: false,
        hasLeavingMessage: false,
        isTransfer: false,
        transferCollaboratorId: null,
        institutionId: this.institutionSelected.id,
      };
      if (
        this.companyId > 0 &&
        this.companiesList.find((el) => el.id == this.companyId)
      ) {
        // this.dataCompanyLoading=true
        let company = this.companiesList.find((el) => el.id == this.companyId);
        this.dataCompany = {
          ...company,
          contacts: company.contacts
            ? company.contacts.map((el) => {
                return { ...el.contact, label: el.label };
              })
            : [],
        };
        // this.$nextTick(() => {
        //     this.dataCompanyLoading=false
        // })
      } else {
        this.dataCompany = {};
      }

      this.dataOrigine = JSON.stringify(this.dataForm);

      if (this.$route.meta.mode == "single") {
        this.options = {
          rules: {
            company: {
              required: [true, "Sélectionnez une société cible"],
            },
            activityDate: {
              required: [true, "Sélectionnez une date"],
            },
          },
        };
      } else {
        this.options = {
          rules: {
            company: {
              required: [true, "Sélectionnez une société cible"],
            },
            collaborator: {
              required: [true, "Sélectionnez un collaborateur"],
            },
            activityDate: {
              required: [true, "Sélectionnez une date"],
            },
          },
        };
      }
      this.key++;
      this.formObj = null;
      this.loading = false;
      this.$nextTick(() => {
        this.formObj = new FormValidator(
          "#formEditActivity" + this.key,
          this.options
        );
      });
    },
    archiveActivityLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveActivities({ activityIds: [id], route: "activity" });
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },
    async setCompany(args) {
      this.dataCompanyLoading = true;

      await this.getCompanyById({ companyId: args.itemData.id });

      this.dataCompany = this.company;

      this.dataCompany.contacts = this.dataCompany.contacts
        ? this.dataCompany.contacts.map((el) => {
            return { ...el.contact, label: el.label };
          })
        : [];

      await this.getActivitiesByCompanyId({
        companyId: this.dataCompany.id,
        updateState: false,
      }).then((res) => {
        this.dataFormActivities = res;
      });

      this.$nextTick(() => {
        this.dataCompanyLoading = false;
      });
    },
    clickAction() {
      this.$refs.overviewgrid.$refs.overviewgrid.addRecord();
    },
    async confirm(andNew = 0) {
      this.$store.commit("SET_IS_CREATING_ACTIVITY", true);
      let formStatus = this.formObj.validate();
      if (formStatus) {
        //this.formObj.element.reset();
        // this.$refs.dialogObj.show();
        this.dataForm.reminderDate
          ? this.dataForm.reminderDate
          : (this.dataForm.reminderDate = null);
        if (this.dataForm.id) {
          await this.updateActivity({
            activity: this.dataForm,
            route: "activity",
          });
        } else {
          await this.createActivity({
            activity: this.dataForm,
            route: "activity",
          });
        }
        if (andNew) {
          this.$store.commit("SET_IS_CREATING_ACTIVITY", true);
          setTimeout(() => {
            this.$store.commit("SET_IS_CREATING_ACTIVITY", false);
            this.initializeForm();
          }, 2000);
        } else {
          this.$store.commit("SET_IS_CREATING_ACTIVITY", false);
          if (this.$route.meta.mode == "single") {
            this.$tabs.close({ to: "/directory/my-activities" });
          } else {
            this.$tabs.close({ to: "/directory/activities-by-collaborators" });
          }
        }
      }
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.dataForm)) {
        if (this.$route.params.routeOrigine) {
          this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
        } else {
          if (this.$route.meta.mode == "single") {
            this.$tabs.close({ to: "/directory/my-activities" });
          } else {
            this.$tabs.close({ to: "/directory/activities-by-collaborators" });
          }
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
  },
};
</script>
<style lang="scss">
.e-chip-list .e-chip:last-child {
  margin-right: 0px !important;
}
.line {
  flex: 1;
  height: 1px;
  background-color: #cccccc;
  margin: 0px 10px;
}
</style>
